export const COLORS = {
    text: {
        light: '#000000', // black
        dark: '#ffffff', // white
    },
    background: {
        light: '#fafafa', // near-white
        dark: '#444444', // near-black
    },
    codeBackground: {
        light: '#dddbdb', // grey for light
        dark: '#6a6767', // grey for dark
    },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
