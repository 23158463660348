exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-comming-soon-jsx": () => import("./../../../src/pages/comming-soon.jsx" /* webpackChunkName: "component---src-pages-comming-soon-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-goals-jsx": () => import("./../../../src/pages/goals.jsx" /* webpackChunkName: "component---src-pages-goals-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-and-terms-of-use-jsx": () => import("./../../../src/pages/privacy-policy-and-terms-of-use.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-and-terms-of-use-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

