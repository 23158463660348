import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { navigate } from 'gatsby';

export const Layout = ({ children }) => {
    useEffect(() => {
        if (
            process.env.GATSBY_MAINTENANCE_MODE === 'true' &&
            location.pathname !== '/'
        ) {
            navigate('/');
        }
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <link
                    href="https://fonts.googleapis.com/css?family=Roboto:400,600&display=swap"
                    rel="stylesheet"
                ></link>
            </Helmet>

            {children}
        </>
    );
};
