import './src/styles/reset.css';
import './src/styles/global.css';
import React from 'react';
import { ThemeProvider } from './src/components/global/ThemeProvider';
import { Layout } from './src/components/global/Layout';

export const wrapRootElement = ({ element }) => (
    <Layout>
        <ThemeProvider>{element}</ThemeProvider>
    </Layout>
);
